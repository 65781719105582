import { useEffect, useState } from 'react';

const useStyle = ({ defaultStyles, hoverStyles }: any, isDisabled: boolean) => {
  const [styles, setStyles] = useState(defaultStyles);

  function handleMouseEnter() {
    setStyles({
      ...defaultStyles,
      ...hoverStyles,
    });
  }

  function handleMouseOut() {
    setStyles(defaultStyles);
  }

  useEffect(() => {
    setStyles(defaultStyles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled]);

  return {
    styles,
    handleMouseEnter,
    handleMouseOut,
  };
};

export default useStyle;
