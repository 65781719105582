import { useState } from 'react';
import { useRouter } from 'next/router';
import clsx from 'clsx';

import Image from 'components/common/Image';
import LinkComponent from 'components/common/Link';
import Markdown from 'components/common/Markdown';

import useMobileDetect from 'hooks/useMobileDetect';

import { ProductProps } from 'interfaces/cms/content';
import styles from '../ProductOverview.module.scss';
import Link from 'next/link';
import { baseUrl } from 'lib/constants';

export interface ProductCardProps extends ProductProps {
  onClickProductOrSeeMore: (slug: string | null, url: string | null) => void;
}

function ProductCard({
  id,
  url,
  hoverUrl,
  alt,
  width,
  height,
  title,
  linkUrl = null,
  linkRel,
  linkSlug = null,
  onClickProductOrSeeMore,
}: ProductCardProps) {
  const { asPath } = useRouter();
  const { isMobile } = useMobileDetect();
  const [isHovering, setIsHovering] = useState(false);

  const onMouseEnter = () => setIsHovering(true);
  const onMouseLeave = () => setIsHovering(false);

  const displayProduct = (
    <div
      key={id}
      className={clsx(
        styles.productOverview__section,
        isHovering && 'bg-primaryColor text-white shadow-none'
      )}
      {...(isMobile
        ? {
            onTouchStart: onMouseEnter,
            onTouchEnd: onMouseLeave,
          }
        : {
            onMouseEnter: onMouseEnter,
            onMouseLeave: onMouseLeave,
          })}
      data-testid={`product-card-${id}`}
    >
      {url && (
        <div
          style={{
            width: `${width}px`,
            height: `${height}px`,
            position: 'relative',
          }}
        >
          <Image
            alt={alt}
            src={isHovering ? hoverUrl || url : url}
            fill
            priority
          />
        </div>
      )}
      <Markdown
        md={title}
        textAlign="center"
        fontSize="0.875rem"
        textColor="#4f4b66"
        pt="0.5rem"
        className={clsx(isHovering && 'text-white')}
      />
    </div>
  );

  let _target = '_self';
  if (!linkSlug && linkUrl && !linkUrl.includes(baseUrl)) {
    _target = '_blank';
  }

  return asPath === '/' ? (
    <Link
      href={linkSlug || linkUrl || ''}
      target={_target}
      onClick={() => onClickProductOrSeeMore(linkSlug, linkUrl)}
    >
      {displayProduct}
    </Link>
  ) : (
    <LinkComponent
      href={linkSlug || linkUrl || ''}
      rel={linkRel || ''}
      isExternalLink={!!linkUrl}
      className="w-auto"
    >
      {displayProduct}
    </LinkComponent>
  );
}

export default ProductCard;
